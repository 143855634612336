//used on the register page
function checkEmail(){
	email1 = $("#emailaddress").val();
	email2 = $("#emailaddress2").val();
	
	if(email1 == email2 && email1.length != 0 && email2.length != 0){
		$("#email-message").empty();
		$("#register-btn").removeAttr("disabled");
	}else{
		$("#email-message").html("");
		if(email1 != email2 && email1.length != 0 && email2.length != 0){
			$("#email-message").html("Email addresses do not match");
		}
		$("#register-btn").attr("disabled", "disabled");
	}
}

function passwordStrength(){
	password = $("#password").val();
	$("#register-btn").attr("disabled", "disabled");
	if (password.length < 8) {
		$("#password-message").html("Password should be at least 8 characters long");
    } else if (password.length > 50) {
        $("#password-message").html("Password is too long");
    } else if (password.search(/\d/) == -1) {
       $("#password-message").html("Password should contain at least 1 number");
    } else if (password.search(/[a-zA-Z]/) == -1) {
        $("#password-message").html("Password doesn't contain letters");
    } else if (password.search(/[^a-zA-Z0-9]/) == -1) {
        $("#password-message").html("Password should contain a special character");
    }else{
		//all ok so show the confirm password field
		$("#password-message").empty();
		$("#register-btn").removeAttr("disabled");
	}
}

function passwordReset(){
	password = $("#password1").val();
	password2 = $("#password2").val();
	$("#password-reset-btn").attr("disabled", "disabled");
	if (password.length < 8) {
		$("#password-message").html("Password should be at least 8 characters long");
    } else if (password.length > 50) {
        $("#password-message").html("Password is too long");
    } else if (password.search(/\d/) == -1) {
       $("#password-message").html("Password should contain at least 1 number");
    } else if (password.search(/[a-zA-Z]/) == -1) {
        $("#password-message").html("Password doesn't contain letters");
    } else if (password.search(/[^a-zA-Z0-9]/) == -1) {
        $("#password-message").html("Password should contain a special character");
    }else{
		
		//check both password fields match
		if(password != password2){
			$("#password-message").html("");
			if(password.length != 0 && password2.length != 0){
				$("#password-message").html("Passwords do not match");
			}
		}else{
			$("#password-message").empty();
			$("#password-reset-btn").removeAttr("disabled");
		}
	
	}
}

function searchReveal(){
	$(".search").toggleClass("search-active");
	$("#search-field").animate({width: 'toggle'},function(){
		this.focus();
	});
}	

function searchStart(){
	$("#search-button").attr("onClick","searchSubmit();");
}

function searchSubmit(){
	$("#search-form").submit();
}

function dismissNotice(){
	$('#cookie-notice-container').fadeOut(function(){
		$('#cookie-notice-container').remove();
	});
}

function bookNow(eventDate){
	//add date to form field
	$("#selected-date").val(eventDate);
	$("#event-submit").val('1');
	$("#upcoming-dates-container").fadeOut(function(){
		$("#event-book-container").fadeIn();
		$("#event-book-container button").removeAttr('disabled');
	});
}

function bookingCancel(){
	$("#event-book-container").fadeOut(function(){
		$("#upcoming-dates-container").fadeIn();
		$("#selected-date").empty();
		$("#event-book-container button").attr('disabled','disabled');
		$("#event-submit").empty();
	})
}

function dismissBrowserNotice(){
	$('#browser-notice-container').fadeOut(function(){
		$('#browser-notice-container').remove();
		//create cookie
		$.cookie('browser-notice', '1');
	});
}
function categoryList(showImages){
	$('.item').addClass('itemlist').removeClass('col-sm-4');
	if(showImages == 1){
		$('.item-img').addClass('col-sm-4');
	}else{
		$('.item-img').addClass('hidden');
	}
	if(showImages == 1){
		$('.item-content').addClass('col-sm-8');
	}else{
		$('.item-content').addClass('col-sm-12');
	}
	
	$('.sort-list').addClass("active");
    $('.sort-grid').removeClass("active");
}

function categoryGrid(showImages){
	$('.item').removeClass('col-sm-12').removeClass('itemlist').addClass('col-sm-4');
	$('.item-img').removeClass('col-sm-4');
	$('.item-content').removeClass('col-sm-8');
	if(showImages == 0){
		$('.item-img').addClass('hidden');
	}
	$('.sort-grid').addClass("active");
    $('.sort-list').removeClass("active");
}
function searchDropdown(){
	if($('.tel-dropdown').is(":visible")) {
		$('.tel-dropdown').slideUp();
		$('.nav-tel').removeClass('active');
	}
	if($('.search-dropdown').is(":visible")) {
		$('.search-dropdown').slideUp();
		$('.nav-search').removeClass('active');
	}else{
	$('.search-dropdown').slideDown();
	$('.nav-search').addClass('active');
	}
}

$("form").submit(function (e) {
	var fail = false;
	var ref = $(this).find("[required]");

	$(ref).each(function () {
		if($(this).context.type == "checkbox") {
			if(!$(this).is(':checked')) {
				fail = true;
				$(this).addClass('warn');
			} else {
				$(this).removeClass('warn');
			}
		} else {
			if($(this).val() == '') {
				fail = true;
				$(this).addClass('warn');
			} else {
				$(this).removeClass('warn');
			}
		}
	});
	if(fail == true) {
		$('html, body').animate({
            scrollTop: $('.warn:first').parents().eq(1).offset().top - 150
        }, 1000);
		$('.warn:first').focus();
		e.preventDefault();
		return false;
	} else {
		return true;
	}
});


//slick slider
$('.credit-banner').slick({
	//centerMode: true,
	dots: false,
	infinite: true,
	speed: 300,
	slidesToShow: 5,
	adaptiveHeight: true,
	arrows: true,
	autoplay: true,
	touchThreshold: 20,
	responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerMode: false,
			}
		}
	]
});

$(function() {
	if( $("div").hasClass( "banner" ) ) {
		$( ".logo-holder" ).removeClass( "headv1__logo-container" );
	}
});







//Sticky Header - only if BANNER exists on the page!!!


$(document).ready(function(){
if( $("div").hasClass( "banner" ) )
{	

    $(".headv1").sticky({ topSpacing: 0 });
}
});

// only if BANNER exists on the page!!!
$(window).on('resize', function() {

	if( $("div").hasClass( "banner" ) )
{	
   //$(".headv1").unstick();
    if($(window).width() >= 750) {
    	console.log('>750');
        $(".headv1").sticky({ topSpacing: 0 });   
        $(".headv1").removeClass("headv1__solid2", 200, "linear"); 
        $('.headv1__logo img').attr("src", "/img/logo.png"); 
        // $( "body" ).removeClass( "body__header-margin" );    
    } else {
    	console.log('<750');
    	//$(".headv1").unstick();
    	$('.headv1__logo img').attr("src", "/img/logo_mob.png"); 
    	$(".headv1").addClass("headv1__solid2", 200, "linear");  	  	   	
    }
    $(".headv1").sticky('update');
}
});



//Makes Header transparrent when scrolled more than 50px
$(function() {
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $(".headv1").addClass("headv1__active", 200, "linear");            
        } else {
            //remove the background property so it comes transparent again (defined in your css)
           $(".headv1").removeClass("headv1__active", 200, "linear");
        }
    });
});


//If class "banner" exists, make the logo smaller when scrolling down the page. On pages 
//with "shield" in the navbar, this script will not fire.

$(function() {
    $(window).on("scroll", function() {
    	if( $("div").hasClass( "banner" ) ) {

	        if($(window).scrollTop() > 50)  { 
	        	$('.headv1__logo img').attr("src", "/img/logo_mob.png");           
	            $(".headv1__logo").addClass("headv1__logo-small");
	        } else {
	           $('.headv1__logo img').attr("src", "/img/logo.png");            
	           $(".headv1__logo").removeClass("headv1__logo-small");
	        }
	    }
    });
});



// //if banner is present, add class to body

if( $("div").hasClass( "banner" ) )
{	
   $( "body" ).addClass( "body__header-margin" );
} else {
	$( "body" ).removeClass( "body__header-margin" );
	$(".headv1").addClass("headv1__solid");	
}

//if banner is not present
// if( !$("div").hasClass( "banner"))
// {
// 	$(".headv1").addClass("headv1__solid");
// 	// $( "body" ).addClass( "body__header-margin" );
// }


//If admin is logged in, make everything solid.
if($("nav").hasClass("admin-navbar"))
{
	$(".headv1").addClass("headv1__solid");
	$( "body" ).removeClass( "body__header-margin" );
	$(".headv1__logo").addClass("headv1__logo-small2");

}	


//if map exists on page, add z-index to logo and the "shield"
$(function() {
	if($("div").is("#map-canvas"))
	{
		$("a.headv1__logo").addClass("logo-z-index");
		$(".logo-holder").addClass("logo-holder-z-index");
	}
});

$(function() {
	if($("div").hasClass("headv1__logo-container"))
	{
		$(".ctn--content").addClass("ctn-padding-top");
		
	}
});



// auto-hide navbar on pages without banner, when scrolling down and show when scrolling up
// function hasScrolled() {
// 	console.log('scroll');
//     var st = $(this).scrollTop();
    
//     // Make sure they scroll more than delta
//     if(Math.abs(lastScrollTop - st) <= delta)
//         return;
    
//     // If they scrolled down and are past the navbar, add class .nav-up.
//     // This is necessary so you never see what is "behind" the navbar.
//     if (st > lastScrollTop && st > navbarHeight){
//         // Scroll Down
//         $('header').removeClass('nav-down', 200, "linear").addClass('nav-up', 200, "linear");
//     } else {
//         // Scroll Up
//         if(st + $(window).height() < $(document).height()) {
//             $('header').removeClass('nav-up', 200, "linear").addClass('nav-down', 200, "linear");
//         }
//     }
    
//     lastScrollTop = st;
// }

// var didScroll;
// var lastScrollTop = 0;
// var delta = 5;
// var navbarHeight = $('header').outerHeight();


// $(function() {
// 	if( !$("div").hasClass( "banner"))
// 	{	
		
// 		$("header").addClass("header-fixed");
// 		$(window).scroll(function(event){
// 		    didScroll = true;
// 		});

// 		setInterval(function() {
// 		    if (didScroll) {
// 		        hasScrolled();
// 		        didScroll = false;
// 		    }
// 		}, 250);

// 	}
// });

// $(window).on('resize', function() {


// 	 if($(window).width() >= 750) {
	    
// 	    $('.headv1__logo img').attr("src", "/img/logo.png"); 
	        
// 	    } 
// 	    else {
	    	
// 	    $('.headv1__logo img').attr("src", "/img/logo_mob.png"); 
	    		  	   	
// 	    }

// });


//Google Recaptcha
var recaptcha1, recaptcha2, recaptcha3;
var recaptchaKey = '<?php echo $recaptchaSiteKey; ?>';
var CaptchaCallback = function(){
    ($('#sidebarRecaptcha').length > 0 ? recaptcha1 = grecaptcha.render('sidebarRecaptcha', {'sitekey' : recaptchaKey}) : null);
    ($('#footerRecaptcha').length > 0 ? recaptcha2 = grecaptcha.render('footerRecaptcha', {'sitekey' : recaptchaKey}) : null);
    ($('#formRecaptcha').length > 0 ? recaptcha3 = grecaptcha.render('formRecaptcha', {'sitekey' : recaptchaKey}) : null);
};

// Testimonial slider
$('.testimonial-slider').slick({
	dots: true,
	infinite: true,
	speed: 300,
	slidesToShow: 1,
	adaptiveHeight: true,
	arrows: true,
	draggable:true,
	autoplay: true
});


// Lightgallery
$(document).ready(function(){
	$('.lightgallery').lightGallery({download: false, counter: false});
});

// Banner slider
$('.main-slider').slick({
	dots: true,
	infinite: true,
	speed: 300,
	slidesToShow: 1,
	adaptiveHeight: true,
	arrows: true,
	draggable:true,
	autoplay: true
});

$('.headv1--search-icon').on('click', function (event) {
    $(this).parents().eq(2).toggleClass('open');
});


// $(function()
//   {
//      $("a#footer__toggle-contact-form").click(function()
//                          {
//                             $("#footer__contact-form").slideToggle();
//                             return false;
//                          }); 
//   });